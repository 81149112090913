import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'
import ContactUs from '../components/ContactForm'

const ContactTheArtist = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPage.title} />
    <ContentBlock>
      <h1>{data.wordpressPage.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: data.wordpressPage.content,
        }}
      />
    </ContentBlock>
    <ContactUs />
  </Layout>
)

export default ContactTheArtist

export const query = graphql`
  query ContactQuery {
    wordpressPage(slug: { eq: "contact" }) {
      title
      content
    }
  }
`
